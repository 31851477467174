import React, { useEffect, useRef, useState } from 'react'
import { useProfile } from '~/contexts/ProfileContext'
import '@css/pages/profile/ProfileHeader.scss'
import emailIcon from '@web/images/profile/email-icon.png'
import linkedInIcon from '@web/images/profile/LinkedIn-icon.svg'
import infoIcon from '@web/images/profile/icon-info.svg'
import pencilIcon from '@web/images/profile/pencil-icon.svg'
import { useAuth } from '~/auth/Auth'
import { getCroppedUploadHandler } from '~/utils'
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap'
import {
  CreateMediaDocument,
  CreateMediaMutation,
  DeactivateUserDocument,
  EditUserDocument,
  MarkViewedForProfileDocument,
} from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { CustomToggle } from '~/common/CustomToggle'
import { ModalCropper } from '~/common/ModalCropper'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { LocationList } from '~/common/LocationList'
import { elementClicked } from '~/common/EventLogger'
import PhotoUploadOptions from '~/common/PhotoUploadOptions'
import { useMutation } from '@apollo/client'
import { usePrompt } from '~/contexts/PromptContext'

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG', 'JFIF']

const clickLinkedIn = (ev: { stopPropagation: () => void }) => {
  ev.stopPropagation()
  globalThis.location.href = '/linkedin/auth_code'
}

export const ProfileHeader = ({ clickEdit }: { clickEdit: () => void }) => {
  const { isCondensedPortrait } = useWindowSize()
  const { loading: profileLoading, user } = useProfile()
  const { loading: authLoading, authUserId, isVeevan, companyId: authUserCompanyId, actingSysAdmin } = useAuth()
  const [viewedProfile] = useMutation(MarkViewedForProfileDocument)

  const [editUser] = useMutation(EditUserDocument)
  const [createMedia] = useMutation(CreateMediaDocument)

  const [uploading, setUploading] = useState(false)
  const [editingPhoto, setEditingPhoto] = useState(false)
  const [addedProfilePhoto, setAddedProfilePhoto] = useState<boolean>(false)
  const fileUploaderRef = useRef<HTMLInputElement>(null)
  const [createMediaResponse, setCreateMediaResponse] = useState<CreateMediaMutation>()
  const [showPhotoEdit, setShowPhotoEdit] = useState<boolean>(false)
  const [cropImage, setCropImage] = useState<File | null>()
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [showEmailUseModal, setShowEmailUseModal] = useState<boolean>(false)
  const [emailUseChecked, setEmailUseChecked] = useState<boolean>(false)
  const [acknowledgedEmailUse, setAcknowledgedEmailUse] = useState(false)
  const requiresAcknowledgement = !isVeevan && !user?.isVeevan && user?.userId != authUserId
  const displayEmail = acknowledgedEmailUse || !requiresAcknowledgement
  const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>(false)
  const [confirmDeactivateUser, setConfirmDeactivateUser] = useState<boolean>(false)
  const [deactivateUser] = useMutation(DeactivateUserDocument)

  const handleFileFileCrop = getCroppedUploadHandler(setUploading, createMedia, setCreateMediaResponse)

  useEffect(() => {
    if (addedProfilePhoto && !uploading) {
      setEditingPhoto(true)
      void editUser({
        variables: {
          user_id: user?.userId ?? '',
          upload_id: createMediaResponse?.createMedia?.uploadedFile?.id,
        },
      }).then(() => setEditingPhoto(false))
    }
  }, [createMediaResponse?.createMedia?.uploadedFile?.id, user?.userId, editUser, addedProfilePhoto, uploading])

  useEffect(() => {
    if (user && !profileLoading && !authLoading && user.userId == authUserId) {
      void viewedProfile()
    }
  }, [user, profileLoading, authLoading, authUserId, viewedProfile])

  const { block, unblock } = usePrompt()
  useEffect(() => {
    if (uploading) {
      block({ key: 'cse', message: 'Photo upload still in progress. Are you sure you want to leave?' })
    } else {
      unblock('cse')
    }
  }, [uploading, block, unblock])

  useEffect(() => {
    if (uploading) {
      window.addEventListener('beforeunload', e => {
        e.preventDefault()
        e.returnValue = ''
      })
    }
  }, [uploading])

  useEffect(() => {
    if (user && confirmDeactivateUser) {
      void deactivateUser({ variables: { userId: user.userId } })
        .then(result => {
          if (result?.data?.deactivateUser?.error) {
            console.error(result?.data?.deactivateUser?.error)
          }
          setConfirmDeactivateUser(false)
          setShowDeactivateModal(false)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [confirmDeactivateUser, deactivateUser, user, user?.hidden])

  const editRef = useClickOnEnter<HTMLImageElement>()

  if (!user && (profileLoading || authLoading)) return null
  if (!user) return <>Could not find user</>

  const clickUploadPhoto = (ev: { stopPropagation: () => void }) => {
    ev.stopPropagation()
    fileUploaderRef.current?.click()
  }

  const clickRemovePhoto = async () => {
    setEditingPhoto(true)
    const removePhotoResp = await editUser({
      variables: {
        user_id: user.userId,
        delete_media: true,
      },
    })
    setEditingPhoto(false)
    if (!removePhotoResp.data?.editUser?.ok) {
      setShowRemoveModal(true)
    }
  }

  const onChangeFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      const e = ev.target.files[0]
      if (e) {
        setCropImage(e)
        setShowPhotoEdit(true)
      }
      ev.target.value = ''
    }
  }

  const authUserIsUser = user && authUserId && user.userId === authUserId
  const usersAreVeevans = (user?.isVeevan && isVeevan) ?? false
  const canEdit = authUserIsUser || usersAreVeevans

  const handleAcknowledgeEmailUse = (e: React.MouseEvent) => {
    elementClicked(e, 'viewed-customer-email-address', { userId: authUserId })
    setShowEmailUseModal(false)
    setAcknowledgedEmailUse(true)
  }

  const clickDeactivate = () => {
    setShowDeactivateModal(true)
  }

  const DeactivateIcon = () => {
    return (
      <svg viewBox="0 0 45 36" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.2656 21.875H12.1641C5.41406 21.875 0 27.3594 0 34.1094C0 35.4453 1.05469 36.5 2.39062 36.5H29.0391C30.375 36.5 31.5 35.4453 31.5 34.1094C31.5 27.3594 26.0156 21.875 19.2656 21.875ZM15.75 18.5C20.6719 18.5 24.75 14.4922 24.75 9.5C24.75 4.57812 20.6719 0.5 15.75 0.5C10.7578 0.5 6.75 4.57812 6.75 9.5C6.75 14.4922 10.7578 18.5 15.75 18.5ZM40.5703 16.25L43.875 12.9453C44.5781 12.2422 44.5781 11.1875 43.875 10.5547C43.2422 9.85156 42.1875 9.85156 41.4844 10.5547L38.25 13.9297L34.875 10.625C34.2422 9.92188 33.1875 9.92188 32.5547 10.625C31.8516 11.2578 31.8516 12.3125 32.5547 13.0156L35.8594 16.3203L32.5547 19.625C31.8516 20.2578 31.8516 21.3125 32.5547 22.0156C33.1875 22.6484 34.2422 22.6484 34.875 22.0156L38.25 18.6406L41.5547 21.9453C42.1875 22.6484 43.2422 22.6484 43.875 21.9453C44.5781 21.3125 44.5781 20.2578 43.875 19.5547L40.5703 16.25Z" />
      </svg>
    )
  }

  return (
    <>
      {showPhotoEdit && !!cropImage && (
        <ModalCropper
          file={cropImage}
          handleBlobUpload={(blob, contentType, fileName) => {
            handleFileFileCrop(blob, contentType, fileName)
            setAddedProfilePhoto(true)
          }}
          onClose={() => {
            setCropImage(null)
            setShowPhotoEdit(false)
          }}
          isCommunity={false}
        />
      )}
      <div className={`user-header ${isCondensedPortrait ? 'condensed' : ''}`}>
        {user.isVeevan || !authUserIsUser ? (
          <div className={`profile-photo ${isCondensedPortrait ? 'condensed' : ''} ${user?.photo ? 'has-photo' : ''}`}>
            <div
              className={'photo'}
              style={{ backgroundImage: `url(${user.photo})`, backgroundSize: 'cover' }}
              title={user.fullName}
              data-company={user.company.name}
            />
          </div>
        ) : (
          <div className={'photo-container'}>
            <div
              className={`profile-photo registration-photo ${user?.photo ? 'has-photo' : ''} ${
                uploading || editingPhoto ? 'uploading' : ''
              }`}
            >
              {uploading || editingPhoto ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <>
                  {!!user.photo && (
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} id={'profile-edit-photo'}>
                        <div
                          className={'photo'}
                          style={{ backgroundImage: `url(${user.photo})`, backgroundSize: 'cover' }}
                          title={user.fullName}
                          data-company={user.company.name}
                        >
                          <div className={'edit-photo'} title={'Edit Photo'} data-testid={'edit-photo'} />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={'edit-photo-dropdown'}>
                        <Dropdown.Item onClick={clickUploadPhoto}>Upload Photo</Dropdown.Item>
                        <Dropdown.Item onClick={clickLinkedIn}>Use LinkedIn Photo</Dropdown.Item>
                        <Dropdown.Item onClick={clickRemovePhoto}>Remove Photo</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </>
              )}
            </div>
            {!user?.photo && (
              <PhotoUploadOptions
                firstName={user.firstName}
                lastName={user.lastName}
                onClickLinkedin={clickLinkedIn}
                onClickUpload={clickUploadPhoto}
              />
            )}
          </div>
        )}
        <div className="profile-info">
          <div className="user-info">
            {canEdit && (
              <img
                className="edit-button"
                alt="Edit"
                src={pencilIcon}
                onClick={clickEdit}
                ref={editRef}
                tabIndex={0}
                role={'edit-profile'}
              />
            )}
            <h1 className="name">{user.fullName}</h1>
            <h2 className="title">{user.title}</h2>
            <h3 className="company">
              {isVeevan || authUserCompanyId === user?.company.companyId ? (
                <Link
                  to={`/companies/${user.company.companyId}`}
                  onClick={e => elementClicked(e, 'click-profile-company')}
                >
                  {user.company.name}
                </Link>
              ) : (
                <span>{user.company.name}</span>
              )}
            </h3>
          </div>
          <hr />
          <div className="contact-info-and-deactivate">
            <div className="contact-info">
              <LocationList user={user} isVeevan={user?.company?.isVeeva} />
              <ul className="user-contacts">
                {user.email && (
                  <li>
                    <img className="icon" alt="Email" src={emailIcon} />
                    {displayEmail ? (
                      <a
                        className={'hover-link'}
                        href={`mailto:${user.email}`}
                        onClick={e => elementClicked(e, 'click-profile-email')}
                      >
                        {user.email}
                      </a>
                    ) : (
                      <span
                        className={'show-contact-info'}
                        onClick={() => setShowEmailUseModal(true)}
                        data-testid={'show-contact-info'}
                      >
                        Show contact info
                      </span>
                    )}
                  </li>
                )}
                {user.linkedinUrl && (
                  <li>
                    <img className="icon round" alt="LinkedIn" src={linkedInIcon} />
                    <a
                      className={'hover-link'}
                      href={`${user.linkedinUrl.startsWith('http') ? '' : 'https://'}${user.linkedinUrl}`}
                      target="_blank"
                      onClick={e => elementClicked(e, 'click-profile-linkedin')}
                    >
                      LinkedIn Profile
                    </a>
                  </li>
                )}
              </ul>
              {user.otherContact && (
                <ul className="other-contacts">
                  <li>
                    <img className="icon round" alt="Other" src={infoIcon} />
                    {user.otherContact}
                  </li>
                </ul>
              )}
            </div>
            {actingSysAdmin && !user.isVeevan && (
              <div className={'deactivate'}>
                <button onClick={clickDeactivate}>
                  <DeactivateIcon />
                  Deactivate User
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <input
        type={'file'}
        name={'file'}
        ref={fileUploaderRef}
        onChange={onChangeFile}
        accept={fileTypes.map(t => `.${t.toLowerCase()}`).join(',')}
        style={{ display: 'none' }}
      />
      <Modal show={showDeactivateModal} onHide={() => setShowDeactivateModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={'deactivate-modal'}>
            <DeactivateIcon />
            <div className={'deactivate-modal-text'}>
              <p style={{ margin: '0, 0, 0, 15px' }}>Are you sure you want to deactivate {user.fullName}?</p>
              <p style={{ margin: '0, 0, 0, 15px' }}>Memberships cannot be recovered once this user is deactivated.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            size="sm"
            disabled={confirmDeactivateUser}
            onClick={e => setShowDeactivateModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={confirmDeactivateUser}
            onClick={e => setConfirmDeactivateUser(true)}
          >
            Yes, Deactivate!
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
        <Modal.Body>We were unable to remove your photo. Please try again.</Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-primary'} onClick={() => setShowRemoveModal(false)}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
      <Modal className={'email-use-modal'} show={showEmailUseModal} onHide={() => setShowEmailUseModal(false)}>
        <Modal.Header>
          <Modal.Title>We love it when our customers connect! And, just to be sure...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              id="email-checkbox"
              type="checkbox"
              checked={emailUseChecked}
              onChange={() => setEmailUseChecked(!emailUseChecked)}
              role={'acknowledge-email'}
            />
            <span onClick={() => setEmailUseChecked(!emailUseChecked)}>
              I will not use this email for sales and marketing purposes. Neither will my company.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn'} onClick={() => setShowEmailUseModal(false)}>
            Cancel
          </button>
          <button
            className={'btn btn-primary'}
            onClick={handleAcknowledgeEmailUse}
            disabled={!emailUseChecked}
            data-testid={'agree-button'}
          >
            I Agree
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
